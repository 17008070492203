import { InputAdornment, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './text-field-meds.scss';

export interface IWemedsTextFieldProps {
    id: string;
    placeholder?: string;
    label?: string;
    isEmail?: boolean;
    isPassword?: boolean;

    value?: string;

    helpText?: string;

    error?: boolean;
    errorText?: string;

    onChange: (value: string) => void;
    onError?: (value: boolean) => void;

    onEnterPress?: () => void;
    style?: any;
    onBlur?: () => void;
    InputProps?: any;
}

const WemedsTextField = (props: IWemedsTextFieldProps) => {
    const [filled, setFilled] = useState<boolean>(props.value != null);
    const [focused, setFocused] = useState<boolean>(false);
    const [label, setLabel] = useState<string>(props.placeholder != null ? props.placeholder : props.label != null ? props.label : '');

    useEffect(() => {
        if (props.value !== null && props.value !== '') {
            setFilled(true);
        }
    }, [props.value]);


    useEffect(() => {
        if (focused) {
            setLabel(props.label ?? '');
        } else {
            setLabel(props.placeholder != null && props.value !== null && props.value !== '' ? props.placeholder : props.label ?? '');
        }
    }, [focused]);

    const onKeyPress = event => {
        if ((event.which === 13 || event.keyCode === 13) && props.onEnterPress) {
            props.onEnterPress();
            return false;
        }
        return true;
    };

    const onKeyDownCapture = event => {
        if (event.which === 9 || event.keyCode === 9) {
            if (label !== props.label && (props.value === null || props.value === '')) {
                setLabel(props.label ? props.label : '');
            }
            return false;
        }
        return true;
    };

    const onFocus = (): void => {
        setFocused(true);
    };
    const onBlur = (): void => {
        setFocused(false);
        if (props.onBlur) {
            props.onBlur();
        }
    };

    const { isPassword, error, errorText, helpText, InputProps, style, id } = props;
    const didNotValidated = filled && (error == null || !focused);
    const hasError = error === true && !focused;
    const isValid = error === false && filled && !focused;
    return (
        <div className="wemeds__text__field" style={{ position: 'relative', width: '100%', ...style }}>
            <TextField
                className={
                    hasError
                        ? 'error filled MuiFilledInput-underline'
                        : isValid
                            ? 'filled validate'
                            : didNotValidated
                                ? 'filled'
                                : 'action'
                }
                id={id}
                type={isPassword ? 'password' : 'text'}
                onChange={e => props.onChange(e.target.value)}
                fullWidth
                autoComplete="off"
                error={hasError}
                InputProps={
                    filled && isValid
                        ? {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div className="check-icn" />
                                </InputAdornment>
                            )
                        }
                        : InputProps || {}
                }
                helperText={errorText && hasError ? errorText : null}
                value={props.value ?? ''}
                label={label}
                variant="filled"
                onKeyDownCapture={onKeyDownCapture}
                onKeyPress={onKeyPress}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            {isPassword === true && helpText && label !== props.label && !hasError && focused ? (
                <div className="helpText">{helpText}</div>
            ) : (
                <div className="blankHelpText" />
            )}
        </div>
    );
};

export default WemedsTextField;
