import React from 'react';
import MaskedInput from 'react-text-mask';

export const MaskCnpj = props => {
    return (
        <MaskedInput
            {...props}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            guide={false}
            placeholder={'__.___.___/____-__'}
        />
    );
};

export const MaskCpf = props => {
    return (
        <MaskedInput
            {...props}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            guide={false}
            placeholder={'___.___.___-__'}
        />
    );
};

export const MaskCardValidity = props => {
    return <MaskedInput {...props} mask={[/\d/, /\d/, '/', /\d/, /\d/]} guide={false} placeholder={'__/__'} />;
};

export const MaskCreditNumber = props => {
    return (
        <MaskedInput
            {...props}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            guide={false}
        />
    );
};


export const MaskZipCode = props => {
    return <MaskedInput {...props} mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]} guide={false} />;
};

export const MaskNumber = props => {
    return <MaskedInput {...props} mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} />;
};
