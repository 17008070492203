import React, { createContext, useContext, useState } from 'react';
import { Address } from '../../model/address';
import { CreateSubscription } from '../../model/createSubscription';

interface SubscriptionProviderProps {
    children: any;
}

interface SubscriptionProviderData {
    createSubscription: CreateSubscription;
    setCreateSubscription: (newCreateSubscription: CreateSubscription) => void;
    currentIndex: number;
    setCurrentIndex: (newValue: number) => void;
    showFinishPayment: boolean;
    setShowFinishPayment: (newValue: boolean) => void;
    address: Address;
    setAddress: (newValue: Address) => void;
}

const SubscriptionContext = createContext<SubscriptionProviderData>({} as SubscriptionProviderData);

export const SubscriptionProvider = ({ children }: SubscriptionProviderProps) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [showFinishPayment, setShowFinishPayment] = useState<boolean>(false);
    const [address, setAddress] = useState<Address>({
        country: 'BR'
    });
    const [createSubscription, setCreateSubscription] = useState<CreateSubscription>({
        twoYearsPromotion: false,
        newUser: false,
        paymentMethod: {},
        couponCode: undefined,
        user: {}
    });

    return (
        <SubscriptionContext.Provider
            value={{
                address,
                setAddress,
                createSubscription,
                showFinishPayment,
                setShowFinishPayment,
                setCreateSubscription,
                currentIndex,
                setCurrentIndex,
            }}
        >
            {children}
        </SubscriptionContext.Provider>
    );
};

export const useSubscriptionContext = () => useContext(SubscriptionContext);
