import { AxiosResponse } from 'axios';
import { api } from './api';

export const couponApi = () => {
    const getCouponDiscount = (couponCode: string): Promise<AxiosResponse<number>> => {
        return api.get<number>(`/coupons/discount`, { params: { couponCode } });
    };

    return {
        getCouponDiscount
    };
};

export default couponApi();
