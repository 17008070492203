import { AxiosResponse } from 'axios';
import CepApi from '../api/cep.api';
import { Address } from '../model/address';
import { CepResponse } from '../model/address/cep-response';
import HttpStatus from '../model/enums/httpStatus';

export const CepService = () => {
    const getAddressByZipCode = async (zipCode: string): Promise<Address> => {
        try {
            const result: AxiosResponse<CepResponse> = await CepApi.getCepInfo(zipCode);
            if (result.status === HttpStatus.OK) {
                const address: Address = {
                    city: result.data.localidade,
                    state: result.data.uf,
                    district: result.data.bairro,
                    street: result.data.logradouro,
                }
                return Promise.resolve(address);
            } else {
                return Promise.reject({ status: 404 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return {
        getAddressByZipCode
    };
};

export default CepService();
