import { AxiosResponse } from 'axios';
import { CepResponse } from '../model/address/cep-response';
import { api } from './api';

const CepApi = () => {
  const getCepInfo = (zipCode: string): Promise<AxiosResponse<CepResponse>> => {
    return api.get<CepResponse>(`https://viacep.com.br/ws/${zipCode.replace(/\D/g, "")}/json`);
  };
  return {
    getCepInfo
  };
};

export default CepApi();
